@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Indie+Flower&display=swap");

body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    overflow-x: hidden;
}

input:focus-visible {
    outline: none;
}

/* login page css starts */

/* login form animation starts */

.form {
    width: 100%;
    height: 50px;
    position: relative;
    overflow: hidden;
    margin-bottom: 10px;
}

.form input {
    width: 100%;
    height: 100%;
    color: #000000;
    padding-top: 25px;
    border: none;
    outline: none;
}

body .btn-close:focus {
    outline: 0;
    box-shadow: none;
}

.myModalDelete .title_ing {
    color: #ff0018 !important;
}

body .modal-body {
    padding: 1rem 1.5rem;
}

body .ml-5 {
    margin-left: 20px !important;
}

.form label {
    position: absolute;
    width: 100%;
    height: 100%;
    bottom: 0px;
    left: 0%;
    pointer-events: none;
    border-bottom: 1px solid black;
}

.form label::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    border-bottom: 3px solid #000;
    bottom: -1px;
    left: 0px;
    transform: translateX(-100%);
    transition: all 0.3s ease;
}

.form label.error {
    border-bottom: 1px solid #dd2c00;
    color: #dd2c00;
}

.content-name {
    position: absolute;
    bottom: 0px;
    left: 0px;
    transition: all 0.3s ease;
}

.form input:focus + .label-name .content-name,
.form input.value_content + .label-name .content-name {
    transform: translateY(-150%);
    font-size: 14px;
    color: #a4cb53;
}

.form input:focus + .label-name::after,
.form input.value_content + .label-name::after {
    transform: translateX(0%);
}

.form input:focus + .label-name .content-name {
    transform: translateY(-150%);
    font-size: 14px;
}

.form input:focus + .label-name::after {
    transform: translateX(0%);
}

.form textarea:focus + .label-name .content-name {
    transform: translateY(0%);
    font-size: 14px;
    color: #9fc849;
}

.form input,
.form textarea {
    padding-right: 60px;
}

.form textarea:focus + .label-name::after {
    transform: translateX(0%);
}

.form textarea {
    width: 100%;
    background: none;
    border: 0;
}

/* login form animation ends */

.login_page_adjust {
    height: 100vh;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f3f3f3;
    flex-direction: column;
}

.login_container {
    background: white;
    width: 415px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    margin: 0 15px;
}

.logo_login {
    padding: 25px;
    background-color: #dee9c6;
    text-align: center;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
}

.logo_login img {
    width: 162px;
    height: auto;
    object-fit: contain;
}

.logo_login form {
    padding: 0 15px;
}

.main_spacing .form-check:not(:last-child) {
    margin-bottom: 10px;
}

.login_container form {
    padding: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.main_spacing .form:not(:last-child) {
    margin-bottom: 20px;
}

.forgot_login {
    margin: 25px 0;
    text-align: center;
}

.forgot_login a {
    color: #6c9714;
    text-decoration: none;
}

button.login_btn {
    width: 100%;
    padding: 7px;
    background: #a4cb53;
    border: 1px solid #9fc849;
    border-radius: 5px;
    color: #ffffff;
    cursor: pointer;
}

button.login_btn:hover {
    transition: 0.2s ease;
    background: #ffffff;
    color: #9fc849;
}

/* login page css ends */

/* main pages css starts */

.main_part {
    display: flex;
    align-items: flex-start;
}

.left_bar {
    max-width: 240px;
    width: 100%;
    background: #ffffff;
    min-height: calc(100vh - 71px);
    border-right: none;
    position: sticky;
    top: 70px;
}

.main_logo img {
    width: 200px;
    height: auto;
    object-fit: contain;
    text-align: center;
}

.left_bar ul a.active {
    border-top: 1px solid #c9c9c9;
    background: #f3f3f3;
    border-bottom: 1px solid #c9c9c9;
    color: #000000;
    font-weight: 500;
}

.main_logo {
    text-align: center;
    background: #ffffff;
    padding: 17px 0;
    box-shadow: 0 3px 4px #00000026;
    max-width: 239px;
    width: 100%;
    position: relative;
    z-index: 3;
}

.left_bar ul a {
    padding: 10px;
}

.left_bar ul {
    padding: 0;
    list-style-type: none;
    margin-top: 15px;
}

.left_bar ul a {
    color: #565656;
    text-decoration: none;
    display: flex;
    align-items: center;
}

.left_bar ul li.active a {
    color: #000000;
    font-weight: 500;
}

header {
    background: #ffffff;
    display: flex;
    align-items: center;
    height: 71px;
    position: sticky;
    top: 0;
    z-index: 9;
}

.right_header {
    display: flex;
    width: 100%;
    justify-content: space-between;
    border-bottom: 1px solid #d5d5d5;
    align-items: center;
    height: 71px;
    padding: 10px 25px;
}

thead th {
    background: #fff !important;
    position: relative;
}

.main_table table {
    box-shadow: 0px 2px 5px #0000002b;
    background: #fff;
}

.right_bar {
    width: 100%;
    background: #f3f3f3;
    min-height: calc(100vh - 71px);
}

.user_image img {
    width: 30px;
    object-fit: contain;
    height: auto;
}

.user_content {
    display: flex;
    align-items: center;
}

.main_title {
    margin-bottom: 0;
    font-family: "Indie Flower", cursive;
}

.user_dropdown button {
    font-size: 14px;
    font-weight: 600;
    padding-right: 0;
}

.user_dropdown .dropdown-item img {
    object-fit: contain;
    height: 23px;
    width: auto;
    margin-right: 15px;
}

.user_dropdown .dropdown-menu {
    padding: 0;
}

.user_dropdown .dropdown-item {
    padding: 10px;
}

body .btn-check:focus + .btn,
body .btn:focus {
    outline: 0;
    box-shadow: none;
}

.user_dropdown .dropdown-menu {
    padding: 0;
    transform: none !important;
    top: calc(100% + 10px) !important;
    bottom: inherit !important;
    right: 0% !important;
    min-width: 150px;
    left: inherit !important;
}

body .dropdown-item.active,
body .dropdown-item:active {
    color: #0c0c0c;
    text-decoration: none;
    background-color: #d9d9d9;
}

.left_bar ul li img {
    object-fit: contain;
    width: 20px;
    height: 20px;
    margin-right: 15px;
}

.left_bar ul li.active img {
    filter: sepia(1);
}

.add_search {
    width: 100%;
    justify-content: space-between;
    padding: 15px;
}

button.add_button {
    background: #9fc849;
    border: 1px solid #9fc849;
    padding: 5px 28px;
    border-radius: 5px;
    color: #fff;
}

button.add_button:hover {
    transition: 0.2s ease;
    background: white;
    color: #9fc849;
}

.add_search {
    width: 100%;
    justify-content: space-between;
    padding: 20px 25px;
}

button.add_button {
    background: #9fc849;
    border: 1px solid #9fc849;
    padding: 7px 28px;
    border-radius: 5px;
    color: #fff;
}

button.add_button:hover,
.publish_faq:hover {
    transition: 0.2s ease;
    background: white;
    color: #9fc849;
}

.search_bar img {
    height: 26px !important;
    height: auto;
    object-fit: contain;
    margin-right: 10px;
    width: auto;
}

.search_bar input {
    border: 0;
    border-bottom: 1px solid;
    background: none;
    max-width: 155px;
    width: 100%;
}

.search_bar input {
    border: 0;
    border-bottom: 1px solid;
    background: none;
    max-width: 155px;
    width: 100%;
}

.search_bar input:focus,
.ing_main_pop input:focus {
    max-width: none;
    outline: none !important;
    transition: all 0.2s cubic-bezier(0.4, 0, 1, 1);
    border-bottom: 1px solid #9fc849;
    width: 100%;
}

.modal-header {
    border-bottom: 0;
}

.ing_content {
    text-align: center;
}

.title_ing {
    font-family: "Indie Flower";
    margin-top: 15px;
    color: #8bc34a;
    font-size: 30px;
}

.add_ing .modal-dialog {
    max-width: 800px;
    margin: 25px auto;
    min-height: calc(100vh - 71px);
    display: flex;
    align-items: center;
}

.myModalDelete .modal-dialog {
    max-width: 450px;
    width: 100%;
}

.add_ing .modal-content {
    border: 1px solid rgb(255 255 255);
}

.ing_main_pop {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 25px;
    margin: 25px 0;
    align-items: baseline;
}

.org_text {
    text-align: center;
    margin: 25px 0;
    color: #000000;
    font-size: 16px;
}

.ing_main_pop_input {
    position: relative;
}

.ingredient_input:not(:last-child) {
    margin-bottom: 25px;
}

.ingredient_input {
    position: relative;
}

p.suggestion_text:last-child {
    margin-bottom: 0;
}

.suggestion_box {
    position: absolute;
    top: 100%;
    background: #fff;
    z-index: 9;
    left: 0;
    min-width: 250px;
    border: 1px solid #e1e1e1;
    padding: 10px;
    height: auto;
    max-height: 120px;
    overflow-y: overlay;
}

.ing_main_pop_input input {
    width: 100%;
    border: 0;
    border-bottom: 1px solid #000;
    font-weight: 500;
    padding-right: 20%;
}

.ing_main_pop_input input.error {
    width: 100%;
    border: 0;
    border-bottom: 1px solid #ff0018;
    font-weight: 500;
}

.ing_main_pop_input input::placeholder {
    font-weight: 400;
}

span.limit {
    position: absolute;
    right: 0;
    bottom: 5px;
    font-weight: 500;
}

span.error_limit {
    position: absolute;
    right: 0;
    font-weight: 500;
    color: #ff0018;
}

button.add_ing_button {
    background: #9fc849;
    border: 1px solid #9fc849;
    padding: 5px 28px;
    border-radius: 5px;
    color: #fff;
    margin-bottom: 15px;
}

button.add_ing_button:hover {
    transition: 0.2s ease;
    background: white;
    color: #9fc849;
}

.ing_main_pop_input.select select {
    width: 100%;
    border: 0;
    border-bottom: 1px solid;
    padding: 3px;
}

.ing_main_pop_input.select select.error {
    width: 100%;
    border: 0;
    border-bottom: 1px solid #ff0018;
    padding: 3px;
}

.modal_less_width .modal-dialog {
    max-width: 500px;
}

.action_icon img {
    height: 20px;
    width: auto;
    object-fit: contain;
}

.action_icon .fa-edit {
    transform: translatey(2px);
}

body input:focus {
    border-color: none;
    outline: 0;
    box-shadow: none !important;
}

.recipe_with_image {
    font-weight: 500;
    display: flex;
    align-items: center;
}

.select_caret_adjust select {
    border: 0;
    border-bottom: 1px solid;
    border-radius: 0;
    padding: 0px;
}

body select.form-select:focus {
    border-color: #242424;
    outline: 0;
    box-shadow: none;
}

body .form-check-input:checked {
    background-color: #9fc849;
    border-color: #9fc849;
}

.new_rec_view_all {
    padding: 15px 25px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.post_now_adjust button {
    display: flex;
    align-items: center;
}

.label_main.sub {
    color: black;
}

.action_icons {
    display: flex;
    align-items: center;
}

.action_icon:first-child {
    margin-right: 20px;
}

.main_table {
    margin: 10px 25px;
}

body .table > :not(caption) > * > * {
    color: #565656;
    vertical-align: middle;
}

.table > :not(:last-child) > :last-child > * {
    border-bottom-color: #dee2e6;
}

body .table > :not(caption) > * > * {
    padding: 0.7rem 0.5rem;
}

tr:nth-child(odd) {
    background: #faffee;
}

.paginate {
    display: flex;
    justify-content: flex-end;
    background: white;
    border: 1px solid #e3e3e3;
}

.pagenum_content {
    display: flex;
    align-items: center;
}

.page_arrows {
    display: flex;
    align-items: center;
}

.page_arrows img {
    height: 24px;
}

.pagenum_content {
    display: flex;
    align-items: center;
    grid-gap: 10px;
}

.main_table th img {
    height: 17px;
    margin-left: 5px;
    top: 12px;
    cursor: pointer;
}

.recipe_table th:nth-child(3) {
    position: relative !important;
    min-width: 125px;
  }

.main_table th img.down_icon {
    margin-left: 20px;
}

.btn_add_ing {
    display: flex;
    flex-direction: column;
}

.modal {
    padding: 10px;
}

.btn_add_ing button {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    padding: 10px;
}

button.add_ing_button.neva {
    transition: 0.2s ease;
    background: white;
    color: #9fc849;
}

button.add_ing_button.neva:hover {
    transition: 0.2s ease;
    background: #9fc849;
    color: #fff;
}

.action_icon {
    display: initial;
}

a {
    cursor: pointer;
}

.only_search {
    padding: 15px 25px;
    justify-content: flex-end;
}

.recipe_with_image img {
    width: 130px;
    height: 110px;
    object-fit: cover;
    margin-right: 10px;
}

.cancel_btn {
    padding: 15px 25px;
}

.cancel_btn img {
    width: auto;
    height: 24px;
}

.form input::placeholder {
    opacity: 0;
}

.form input:focus::placeholder {
    opacity: 1;
}

.cancel_btn {
    padding: 15px 25px;
}

.cancel_btn a {
    color: black;
    text-decoration: none;
    display: inline-flex;
    align-items: center;
    font-weight: 600;
}

.b-0 .limit {
    bottom: 0;
}

.bg_remove {
    background: none;
}

.cancel_btn a:hover {
    transition: 0.2s ease;
    color: #ff4155;
}

.main_spacing {
    padding: 0 25px;
}

.label-name {
    font-weight: 500;
}

body .form-check-input:checked {
    background-color: #9fc849;
    border-color: #9fc849;
}

.label_main {
    font-weight: 500;
    margin-bottom: 10px !important;
    color: #9fc849;
}

.select_caret_adjust {
    margin-bottom: 10px;
}

.contributor_upload input[type="submit"] {
    background: #9fc849;
    border: 1px solid #9fc849;
    padding: 5px 28px;
    border-radius: 5px;
    color: #fff;
}

form.contributor_upload {
    display: flex;
    align-items: center;
}

.contributor_upload input[type="submit"]:hover {
    transition: 0.2s ease;
    background: white;
    color: #9fc849;
}

.form textarea:focus-visible {
    border: 0;
    outline: none;
}

.text_area textarea {
    width: 100%;
    padding-right: 60px;
    border: 0;
    border-bottom: 1px solid #000;
}

.text_area textarea.error_description {
    border-bottom: 1px solid #ff0018;
}

.arrow_page button {
    border: 0;
    background: #fff;
    font-family: monospace;
    font-size: 18px;
    font-weight: 500;
}

.textarea_adjust .content-name {
    bottom: inherit !important;
    top: -5px;
}

.info_upload {
    font-size: 12px;
    font-weight: 500;
}

.info_upload_desc {
    font-size: 12px;
}

.upload_boxes {
    width: 80%;
    display: flex;
    margin: 25px 0;
}

.upload_boxes_multi img {
    width: 100%;
    height: auto;
    object-fit: cover;
}

.upload_boxes_multi {
    flex: 1;
    display: flex;
    position: relative;
    cursor: pointer;
}

.image_section {
    display: flex;
    margin-top: 25px;
    width: 100%;
}

.upload_boxes_multi:not(:last-child) {
    margin-right: 25px;
}

.upload_box i {
    object-fit: contain;
    height: auto;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-size: 35px;
    color: #6e6e6e;
    cursor: pointer;
}

.upload_box {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #eee;
    min-width: 150px;
    height: 175px;
    position: relative;
}

.upload_box input {
    background: #eeeeee;
    height: 174px;
    width: 100%;
    position: relative;
    z-index: 2;
    opacity: 0;
    cursor: pointer;
}

.upload_box:not(:last-child) {
    margin-right: 25px;
}

.video_url_adjust {
    width: 60%;
}

.video_url_adjustment {
    display: flex;
    align-items: baseline;
}

button.video_btn_url,
button.section_add {
    margin-left: 25px;
    background: #9fc849;
    border: 1px solid #9fc849;
    padding: 5px 28px;
    border-radius: 5px;
    color: #fff;
}

button.video_btn_url:hover,
button.section_add {
    transition: 0.2s ease;
    background: white;
    color: #9fc849;
}

.section_add {
    margin-left: 0 !important;
    margin-top: 25px;
}

.width-auto {
    width: max-content !important;
}

.label_main.ing_adjust {
    color: #000;
    margin-top: 25px !important;
}

.ing_three_main {
    display: flex;
    align-items: self-end;
    width: 90%;
}

.first_qty {
    flex: 3 1;
    margin-right: 0px;
    position: relative;
}

.sec_qty {
    flex: 7;
    margin-right: 25px;
    position: relative;
}

.third_qty {
    flex: 7;
    position: relative;
}

.click_to_add button {
    width: 70%;
    background-color: #eee;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 3px;
    color: #909090;
    font-size: 13px;
    cursor: pointer;
    text-indent: 10px;
    text-align: left;
    padding: 10px;
    font-weight: 600;
}

.checkbox_container {
    margin: 25px 0;
    color: #678f13;
    font-weight: 500;
}

.checkbox_container input {
    margin-right: 10px;
}

.check_box_sep:not(:last-child) {
    margin-bottom: 5px;
}

.done_main button {
    width: 170px;
    justify-content: center;
}

li.done_main button {
    margin: 0.25rem 1rem;
    background: #9fc849;
    border: 1px solid #9fc849;
    padding: 5px 28px;
    border-radius: 5px;
    color: #fff;
    margin-top: 5px;
}

.contributor.contributor_upload .submit {
    position: absolute;
    left: 0;
}

.contributor.contributor_upload {
    position: relative;
}

.contributor.contributor_upload input[type="file"] {
    opacity: 0;
    width: 118px;
    position: relative;
    z-index: 3;
}

li.done_main button:hover {
    transition: 0.2s ease;
    background: white;
    color: #9fc849;
}

.post_now_adjust ul {
    min-width: 295px;
    width: 100%;
}

/* tab starts */

/* Style the tab */

.tab {
    overflow: hidden;
    border: 1px solid #ccc;
    background-color: #f1f1f1;
}

/* Style the buttons inside the tab */

.tab button {
    background-color: inherit;
    float: left;
    border: none;
    outline: none;
    cursor: pointer;
    padding: 14px 16px;
    transition: 0.3s;
    font-size: 17px;
}

/* Change background color of buttons on hover */

.tab button:hover {
    background-color: #ddd;
}

/* Create an active/current tablink class */

.tab button.active {
    background-color: #9fc849;
    color: #fff;
}

/* Style the tab content */

.tab_content {
    display: none;
    border-top: none;
}

/* tab ends */

.aisle_pop_adjust {
    width: 90%;
    margin: auto;
}

.pop_adjust {
    width: 90%;
    margin: 25px auto 0;
}

.upload_boxes.guide_adjust .upload_box {
    max-width: 300px;
    width: 100%;
}

body .mt-5 {
    margin-top: 25px !important;
}

.main_spacing.container {
    margin-left: 0;
}

.faq_container {
    background: white;
    padding: 15px;
    box-shadow: 0px 2px 5px #0000002b;
    margin-top: 25px;
}

.faq_content button {
    width: 96%;
    color: #333;
    background-color: #ffffff;
    border-color: #ddd;
    font-weight: 500;
}

.faq_content button {
    text-align: left;
}

.faq_content {
    padding: 15px 25px;
    background: #f3f3f3;
    border: 1px dotted #9fc849;
}

.faq_content .btn-check:focus + .btn-success,
.faq_content .btn-success:focus,
.faq_content .btn-success:hover {
    color: inherit;
    background-color: inherit;
    border-color: inherit;
    box-shadow: inherit;
}

.faq_content:not(:last-child) {
    margin-bottom: 25px;
}

.faq_all div {
    padding: 15px;
    background: white;
    border: 1px solid #ddd;
    border-top: 0;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    transition: 100ms cubic-bezier(0.4, 0, 1, 1);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 96%;
    margin-left: 26px;
}

.faq_content {
    display: flex;
    align-items: baseline;
}

.faq_all {
    width: 100%;
}

.faq_action_icons {
    display: flex;
    align-items: center;
}

.faq_action_icons div {
    height: 30px;
    width: 30px;
    margin: 0 10px;
}

.faq_edit {
    background: #9fc849;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    border-radius: 5px;
}

.faq_trash {
    background: #ff0000;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    border-radius: 5px;
    margin: 0px !important;
}

.faq_action_icons a {
    text-decoration: none;
}

.faq_add_main {
    margin: 25px 0 15px;
}

.faq_main_btns {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.faq_add_main {
    margin: 25px 0 15px;
    background: #000000;
    border: 1px solid #000000;
    padding: 7px 28px;
    border-radius: 5px;
    color: #fff;
}

.faq_add_main:hover {
    background: #fff;
    color: #000;
    transition: 0.2s ease;
}

button.publish_faq {
    background: #9fc849;
    border: 1px solid #9fc849;
    padding: 7px 28px;
    border-radius: 5px;
    color: #fff;
}

.faq_all .answer_tag {
    padding: 2px 5px;
    font-size: 12px;
    background: #3c8dbc;
    color: #fff;
    font-weight: 600;
    border-radius: 4px;
    margin-bottom: 10px;
}

.modal-body textarea {
    width: 100%;
}

.faq_edit_adjust .modal-header {
    border-bottom: 1px solid #dee2e6;
}

.enter_email {
    font-weight: 600;
    font-size: 18px;
}

.action_icon i {
    font-size: 21px;
    color: #6e6e6e;
    cursor: pointer;
}

.enter_subemail {
    margin: 10px 0;
    font-size: 14px;
}

.search_bar {
    width: 320px;
    position: relative;
    justify-content: flex-end;
}

.search_bar input {
    width: 100%;
    padding-left: 20px;
}

.search_bar i {
    color: #776e6e;
    font-size: 16px;
    left: 0;
    transform: translateX(12px) translateY(-2px);
}

.form_card {
    background: #fff;
    box-shadow: 0px 2px 5px #0000002b;
    padding: 10px;
    margin-bottom: 25px;
}

.select_caret_adjust.recipe_adjust {
    display: flex;
    align-items: self-end;
}

.recipe_adjust .only_search {
    padding: 0 25px;
}

/* media starts  */

@media (max-width: 800px) {
    .ing_three_main,
    .click_to_add button {
        width: 100%;
    }

    .main_table table {
        overflow-x: scroll;
        width: 100%;
    }
}

@media screen and (min-device-width: 601px) and (max-device-width: 800px) {
    .recipe_with_image {
        align-items: flex-start;
        flex-direction: column;
    }
}

@media (max-width: 600px) {
    .login_container {
        width: calc(100vw - 5vw);
        max-width: none;
        margin: auto;
    }

    .recipe_adjust .only_search {
        padding: 0 25px;
        margin: 10px 0;
    }

    .recipe_adjust select {
        margin: 0 15px;
    }

    .recipe_main_adjust {
        flex-direction: column;
    }

    .recipe_adjust {
        flex-wrap: wrap;
    }

    .faq_content {
        flex-direction: column;
        padding: 10px;
    }

    .faq_action_icons {
        margin-top: 10px;
    }

    .faq_content:not(:last-child) {
        margin-bottom: 10px;
    }

    .faq_container {
        padding: 0;
    }

    .right_header {
        margin-top: 15px;
        border-top: 1px solid #d5d5d5;
    }

    .upload_box input {
        height: auto;
    }

    .left_bar {
        position: inherit;
    }

    .main_spacing {
        padding: 0 10px;
    }

    button.add_button {
        margin-right: 10px;
        margin-bottom: 10px;
    }

    .upload_boxes {
        width: 100%;
        flex-direction: column;
    }

    .upload_box {
        padding: 50px 10px;
    }

    .upload_box img {
        width: 40px;
    }

    .upload_box:not(:last-child) {
        margin-bottom: 25px;
        margin-right: 0px;
    }

    .recipe_with_image {
        display: flex;
        min-width: 260px !important;
        align-items: center;
    }

    body .table > :not(caption) > * > * {
        min-width: 150px;
    }

    .main_table th img {
        height: 17px;
        margin-left: 5px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 10px;
    }

    header {
        flex-direction: column;
        height: auto;
        align-items: flex-start;
    }

    .main_part {
        flex-direction: column;
    }

    .left_bar {
        max-width: none;
        width: 100%;
        min-height: auto;
        border-right: 0;
    }

    .add_search {
        padding: 15px 10px;
        flex-wrap: wrap;
    }

    .main_table {
        margin: 10px;
    }

    .ing_main_pop {
        grid-template-columns: 1fr;
    }

    /*  toggle */
    #myLinks {
        display: none;
    }

    .topnav img {
        color: white;
        padding: 14px 16px;
        text-decoration: none;
        font-size: 17px;
        display: block;
    }

    .topnav img.icon {
        background: #9fc849;
        display: flex;
        position: absolute;
        right: 10px;
        top: 10px;
        flex-direction: column;
        align-items: center;
        padding: 5px;
        border-radius: 5px;
        z-index: 999;
        height: 30px;
        cursor: pointer;
    }

    .topnav img:hover {
        background-color: #ddd;
        color: black;
    }

    .topnav .active {
        background-color: #04aa6d;
        color: white;
    }

    .topnav img.icon span {
        color: white;
        margin-bottom: 0;
        line-height: 3px;
        background: white;
        width: 18px;
        height: 3px;
    }

    .topnav a.icon span:not(:last-child) {
        margin-bottom: 3px;
    }

    .main_logo {
        margin-left: 10px;
        text-align: left;
        background: none;
        padding: 10px 0 0;
        box-shadow: none;
    }

    ul#myLinks {
        position: absolute;
        top: 46px;
        z-index: 9;
        background: #fff;
        width: 100%;
        box-shadow: 0 2px 5px #a1a1a1;
    }

    .left_bar ul li {
        padding: 0;
    }

    /*  toggle ends */
    .main_title {
        margin-right: 10px;
    }

    .right_header {
        padding: 10px;
    }

    .cancel_btn {
        padding: 15px 10px;
    }

    .only_search {
        padding: 15px 0;
    }
}

.loader {
    height: 6px;
    width: 100%;
    position: relative;
    overflow: hidden;
    background-color: #ddd;
}

.loader:before {
    display: block;
    position: absolute;
    content: "";
    left: -200px;
    width: 200px;
    height: 6px;
    background-color: #a4cb53;
    animation: loading 2s linear infinite;
}

@keyframes loading {
    from {
        left: -200px;
        width: 30%;
    }

    50% {
        width: 30%;
    }

    70% {
        width: 70%;
    }

    80% {
        left: 50%;
    }

    95% {
        left: 120%;
    }

    to {
        left: 100%;
    }
}

.page_not_found {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 50px;
}

.page_not_found img {
    width: 35%;
    height: auto;
    object-fit: contain;
}

.page_not_found button {
    margin-top: 50px;
}

.page_not_exist {
    padding: 50px 10px 0;
    font-size: 20px;
    font-weight: 500;
}

.text_page_not_found {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.text_page_not_found h4 {
    margin: 40px 0;
}

.back_to_login {
    background: #9fc849;
    border: 1px solid #9fc849;
    padding: 5px 28px;
    border-radius: 5px;
    color: #fff;
    text-decoration: none;
}

.back_to_login:hover {
    transition: 0.2s ease;
    background: white;
    color: #9fc849;
}

.toggle div {
    display: flex;
    padding-left: 0;
    align-items: center;
}

.toggle .form-switch .form-check-input {
    margin-top: 0;
    margin-left: 10px;
    margin-right: 10px;
    cursor: pointer;
}

.invalid_email {
    font-size: 12px;
    padding: 5px;
    width: 93%;
    text-align: center;
    margin: 10px auto;
}

.input_validation {
    text-align: left;
    display: block;
    width: 100%;
    color: #ff0000;
    font-size: 12px;
    transform: translateY(-10px);
}

button#pageDropDown {
    color: #000 !important;
    background-color: #d3d7e7;
    border-color: #d3d7e7;
}

.arrow_page button i {
    font-size: 23px;
}

.input_validation_error {
    text-align: left;
    display: block;
    width: 100%;
    color: #ff0000;
    font-size: 12px;
}

span.error_limit.b-0 {
    bottom: 0;
}

.ing_main_pop_input.select select:focus-visible {
    outline: none;
}

.validation_error.input_validation {
    width: 90%;
    margin: auto;
    transform: none;
    margin-top: 5px;
}

.trash_icon {
    position: absolute;
    right: 0;
    bottom: 0;
    cursor: pointer;
}

.trash_icon.qty_adjust {
    right: -35px;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    font-size: 22px;
}

.text_area_format_adjust textarea {
    border: 1px solid #e1e1e1 !important;
}

.text_area_format_adjust textarea:focus-visible {
    outline: none;
}

.text_area_format_adjust .limit {
    right: 3px;
    transform: translateY(3px);
}

.format_textarea_content {
    width: 80%;
    margin: auto;
    position: relative;
}

.format_textarea_content .trash_icon.qty_adjust {
    top: -10px;
}

span.number {
    position: absolute;
    left: -45px;
    top: 50%;
    display: flex;
    align-items: center;
    background: #9fc849;
    border-radius: 50%;
    transform: translateY(-50%);
    height: 25px;
    width: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    color: #fff;
    font-size: 14px;
}

.first_qty input {
    width: 85px;
    height: 35px;
    text-align: center;
}

.select_caret_adjust select.select_error {
    border-bottom: 1px solid #ff0018;
}

.b-5 {
    bottom: 5px;
}

textarea:focus-visible {
    outline: none;
}

#text {
    color: #ff0018;
}

button#pageDropDown {
    background: none;
    border: 0;
    outline: none;
    box-shadow: none;
}

ul.dropdown-menu[aria-labelledby="pageDropDown"] {
    bottom: 100%;
}

ul.dropdown-menu[aria-labelledby="pageDropDown"] a {
    color: #000;
    text-decoration: none;
    cursor: pointer;
    width: 100%;
    display: block;
}

.paginate .dropdown-item {
    padding: 0.25rem 1rem;
    cursor: pointer;
}

.ing_three_main .input_validation {
    position: absolute;
    transform: translate(0px, 0px);
}

.first_qty .input_validation {
    transform: translateY(-10px);
}

.click_to_add {
    transform: translateY(10px);
}

.dropzone {
    height: 100px;
    padding: 0px;
    cursor: pointer;
    border: 2px dashed #c4bfbf;
    border-radius: 24px;
}

.dropzone-area {
    border-color: "#ced4da";
}

.dropzone-small-text {
    position: absolute;
    bottom: 113px;
}

.media-dropzone .dropzone {
    height: 110px;
}

.w-100 {
    width: 100% !important;
}

.c-flex-directionnew {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.mt20 {
    margin-top: 20px !important;
}

.icon-image2,
.image-container2 {
    position: relative;
    height: 91px;
    width: 87px;
    margin-left: 0;
    border-radius: 15px;
    display: flex;
    object-fit: cover;
    align-items: center;
    object-position: center;
    margin-right: 10px;
}

.ml-2,
.mx-2 {
    margin-left: 0.5rem !important;
}

.choose_orient,
.choose_orient label,
.close-icon-new {
    display: flex;
    align-items: center;
}

.close-icon-new {
    border: 0;
    height: 20px;
    width: 20px;
    justify-content: center;
    font-size: 18px;
    border-radius: 99999px;
    position: absolute;
    top: -11px;
    right: -11px;
}

.text-white {
    color: #fff !important;
}

.bg-danger {
    background-color: #dc3545 !important;
}

.p-0 {
    padding: 0 !important;
}

.image-container2 .custom-upload-img {
    width: 95px;
    height: 100px;
    border-radius: 15px;
    object-fit: cover;
    object-position: center;
    align-items: center;
}

.image-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.mr-2,
.mx-2 {
    margin-right: 0.5rem !important;
}

.c-flex,
.c-flex-center-column {
    display: flex;
    align-items: center;
}

.icon-image2,
.image-container2 {
    position: relative;
    height: 91px;
    width: 87px;
    margin-left: 0;
    border-radius: 15px;
    display: flex;
    object-fit: cover;
    align-items: center;
    object-position: center;
    margin-right: 10px;
}

#break-word {
    word-break: break-all;
}

.word-break {
    word-break: break-word;
}

#break-word {
    word-break: break-all;
}

.text-eclipse {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    max-width: 540px;
}

.c-icon-square-dash,
.c-icon-square-dash.manage-admin {
    width: 40px;
    height: 30px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.c-icon-square-dash {
    margin-left: 10px !important;
}

.delete-square {
    background: #fee6e6;
    cursor: pointer;
    border: none;
}

.c-icon-square-dash img {
    height: 20px !important;
}

.cropContainer {
    position: "relative";
    width: 100%;
    height: 200px;
    background: "#333";
}

.upload_boxes_multi img {
    width: 100%;
    height: auto;
    object-fit: contain;
    max-height: 175px;
    display: inline-block;
    max-width: 240px;
    object-position: top right;
}

.upload_boxes_multi button {
    position: absolute;
    right: -10px;
    top: -10px;
}

.css-b62m3t-container:focus-visible {
    outline: none;
}

.select_caret_adjust:focus-visible {
    outline: none;
}

.css-1pahdxg-control * {
    outline: none;
}

.spinner-border {
    position: absolute;
    top: calc(50% - 15px);
    z-index: 3;
    background: #eeeeee;
    left: calc(50% - 15px);
}

.image_section.image {
    max-width: 33.33%;
}

.form-check.check_boxes {
    margin-left: 23px;
}

.label_main.sub.error {
    color: #ff0000;
}

body .upload_box_content {
    width: 90%;
    margin: auto;
    display: inline-flex;
    flex: none;
}

.image-pre {
    max-width: 33.33%;
    margin-bottom: 2%;
}

.upload_boxes_multi.mw-90 img {
    height: 200px;
    width: 90% !important;
    margin: auto;
}

.upload_boxes_multi.mw-90 button {
    right: 15px;
}

.tox.tox-tinymce {
    height: 240px !important;
}

.overflow-y-hidden {
    overflow-y: hidden !important;
}

select.form-select.serving_size {
    width: 250px;
}

.modal-footer {
    width: 100% !important;
    display: block;
}

span.error_limit.b-3 {
    bottom: 3px;
}

button.add_button.mt-5.disabled {
    opacity: 0.8;
}

button.add_button.mt-5.disabled:hover {
    background: #9fc849;
    border: 1px solid #9fc849;
    padding: 7px 28px;
    border-radius: 5px;
    color: #fff;
    opacity: 0.8;
}

.btn_add_ing button.disabled {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    padding: 10px;
    opacity: 0.8;
}

.btn_add_ing button.disabled:hover {
    opacity: 0.8;
    transition: 0.2s ease;
    background: #9fc849;
    color: #fff;
}

button.add_button.disabled {
    background: #9fc849;
    border: 1px solid #9fc849;
    padding: 7px 28px;
    border-radius: 5px;
    color: #fff;
    opacity: 0.9;
}

th {
    outline: none !important;
}

/* toggle switch  */

.switch {
    position: relative;
    display: inline-block;
    width: 30px;
    height: 33px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 10px;
    left: 0;
    right: 0;
    bottom: 10px;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 17px;
    width: 16px;
    left: -5px;
    bottom: -2px;
    background-color: #9b9b9b;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

input:checked + .slider {
    background-color: #afe968;
}

input:focus + .slider {
    box-shadow: 0 0 1px #afe968;
}

input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}

.toggle-switch {
    display: flex;
    align-items: center;
}

.toggle-switch p {
    margin-bottom: 0;
}

.toggle-switch .switch {
    margin: 0 15px;
}

.toggle_heading {
    font-family: "Indie Flower";
    margin-top: 15px;
    color: red;
    font-size: 30px;
}

/* Darshan New */

.button_dropdown {
    position: absolute;
    inset: auto auto 0px 0px;
    margin: 0px;
    transform: translate(0px, 85px);
}

.filter_select {
    background-color: #f3f3f3;
    width: 140px;
}

.filter_select option {
    text-transform: capitalize;
}

.form-check-input:focus {
    border: 1px solid rgba(0, 0, 0, 0.25);
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}

.form-switch .form-check-input:focus {
    background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='rgba%280, 0, 0, 0.25%29'/></svg>");
}

.staging {
    padding: 8px;
    background: #ff0000;
    color: #fff;
    font-weight: 500;
    font-size: 30px;
    margin-bottom: 25px;
    font-family: "Indie Flower";
    width: 100%;
    width: 415px;
    text-align: center;
    border-radius: 5px;
    text-transform: uppercase;
}

.right_header .staging {
    margin-bottom: 0;
    font-weight: 600;
    font-size: 22px;
    margin-left: 100px;
}

i.fa.fa-arrows:hover {
    cursor: grab;
}

.page_dropdown {
    position: absolute;
    inset: auto auto 0px 0px;
    margin: 0px;
    transform: translate(0px, -40px);
}

/*** schedule ***/

.post_now.show:before {
    content: "";
    height: 7px;
    width: 15px;
    position: absolute;
    background: #ffffff;
    top: 50%;
    transform: translateY(-50%) rotate(270deg);
    right: -5px;
    clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
}

.post_now.show:after {
    transform: rotate(270deg);
    backface-visibility: hidden;
}

.ul {
    left: 100%;
    top: 50%;
    transform: translateY(-50%);
    bottom: inherit;
    border: none;
    box-shadow: 0 0 3px #9d9d9d;
}

.schedule {
    display: flex;
    justify-content: space-between;
    margin: 0.25rem 1rem;
    border: 1px solid #dadada;
    padding: 5px;
    width: calc(100% - 2rem);
}

.schedule > * {
    flex: 1;
    width: 100%;
}

.schedule * {
    color: #545454 !important;
}

.schedule > select {
    border: 0;
    background: #fff;
    outline: none !important;
    min-width: 60px;
    margin-left: -10px;
}

.schedule li input {
    width: 90%;
    border: 0;
}

button.schedule_button {
    text-align: center;
    margin: 10px auto;
    width: calc(100% - 30px);
    justify-content: center;
}
/*** schedule ***/

/*** FAQ ***/

.mr-10 {
    margin-right: 10px;
}

.faq_action_icons div:hover {
    cursor: pointer;
}

.mb-25 {
    margin-bottom: 25px;
}

.tags_content {
    display: flex;
    flex-wrap: wrap;
}

.tag_details {
    color: #9fc849;
    font-size: 13px;
    border: 1px solid #9fc849;
    padding: 6px 30px;
    margin: 0 20px 20px 0;
    font-weight: 700;
    border-radius: 30px;
    cursor: pointer;
}

.tag_details.active {
    background: #9fc849;
    color: #fff;
}

.css-1s2u09g-control {
    border-bottom: 1px solid black !important;
    border-left: none !important;
    border-right: none !important;
    border-top: none !important;
    border-radius: 0px !important;
}

.css-1pahdxg-control {
    outline: none !important;
}

.css-1pahdxg-control:hover {
    border-bottom: 1px solid black !important;
    border-left: none !important;
    border-right: none !important;
    border-top: none !important;
    border-radius: 0px !important;
    box-shadow: none !important;
    outline: none !important;
}

.css-1pahdxg-control:not(:focus) {
    border-bottom: 1px solid black !important;
    border-left: none !important;
    border-right: none !important;
    border-top: none !important;
    border-radius: 0px !important;
    box-shadow: none !important;
    outline: none !important;
}

.css-1okebmr-indicatorSeparator {
    display: none !important;
}

.css-tj5bde-Svg {
    fill: #606060 !important;
}

.css-14el2xx-placeholder {
    color: #242424 !important;
    margin: 0px !important;
}

.css-319lph-ValueContainer {
    padding: 0px !important;
}

.section_validation {
    transform: translateY(-18px);
}

.export_btn {
    display: flex;
    justify-content: flex-end;
}

.cards {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    margin-bottom: 24px;
    border: 0;
    box-shadow: 0px 8px 172px 0px rgb(42 48 66 / 10%);
    border-radius: 4px;
    margin-bottom: 24px;
}

.card-body {
    flex: 1 1 auto;
    padding: 1.25rem 1.25rem;
}

.card-content {
    display: flex;
    align-items: flex-start;
}

.card-content-body {
    flex: 1 1;
}

.text-muted {
    --bs-text-opacity: 1;
    color: #565656 !important;
}

.search_bar_user img {
    height: 26px !important;
    height: auto;
    object-fit: contain;
    margin-right: 10px;
    width: auto;
}

.search_bar_user input {
    border: 0;
    border-bottom: 1px solid;
    background: none;
    max-width: 155px;
    width: 100%;
    padding-left: 15px;
}

.search_bar_user input:focus,
.ing_main_pop input:focus {
    max-width: none;
    outline: none !important;
    transition: all 0.2s cubic-bezier(0.4, 0, 1, 1);
    border-bottom: 1px solid #9fc849;
    width: 100%;
}

.search_bar_user i {
    color: #776e6e;
    font-size: 16px;
    left: 0;
    transform: translateX(12px) translateY(-2px);
}

.search_bar_user {
    width: 320px;
    position: relative;
    justify-content: flex-start;
}

.search_bar_recipe img {
    height: 26px !important;
    height: auto;
    object-fit: contain;
    margin-right: 10px;
    width: auto;
}

.search_bar_recipe input {
    border: 0;
    border-bottom: 1px solid;
    background: none;
    max-width: 155px;
    width: 100%;
    padding-left: 15px;
}

.search_bar_recipe input:focus,
.ing_main_pop input:focus {
    max-width: none;
    outline: none !important;
    transition: all 0.2s cubic-bezier(0.4, 0, 1, 1);
    border-bottom: 1px solid #9fc849;
    width: 100%;
}

.search_bar_recipe i {
    color: #776e6e;
    font-size: 16px;
    left: 0;
    transform: translateX(12px) translateY(-2px);
}

.search_bar_recipe {
    position: relative;
    justify-content: flex-start;
    padding: 20px 25px;
    float: right;
}

.mr-35 {
    margin-right: 35px;
}

.filter_dropdown {
    position: absolute;
    z-index: 2;
    background: #fff;
    padding: 15px 5px;
    border-radius: 8px;
    top: 100%;
    margin-top: 15px;
    left: -56px;
    border: 1px solid #e1e1e1;
    width: 235px;
}

.filter-title {
    border-bottom: 1px solid #dfdfdf;
    padding: 0 10px;
    margin: 0 10px;
    padding-bottom: 5px;
    font-weight: 500;
    padding-left: 0;
}

.filter_categories {
    position: relative;
}

.filter-check-box input {
    margin-right: 10px;
}

.filter-check-box {
    margin-left: 0px;
    padding: 5px 0;
    padding-left: 0.5em;
    padding-right: 1.5em;
    font-size: 15px;
    display: flex;
}

.filter_dropdown:before {
    content: "";
    top: -22px;
    left: 48%;
    position: absolute;
    transform: rotate(270deg) translateX(-50%);
    background-image: url(../images/up_filter.png);
    background-size: contain;
    height: 16px;
    width: 16px;
    background-position: center;
    opacity: 0.3;
}

.filter_button {
    font-size: 13px;
    padding: 7px 12px !important;
}

.subtitle {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 90%;
    margin-top: 15px;
}

.subtitle_content {
    display: flex;
    align-items: center;
}

.subtitle_content_values {
    display: flex;
    align-items: center;
}

.subtitle_content input {
    border: 0;
    border-bottom: 1px solid;
    width: 215px;
}

.subtitle_content_values input {
    border: 0;
    border-bottom: 1px solid;
    width: 200px;
    margin-right: 10px;
    font-size: 14px;
}

.subtitle_content input::placeholder {
    color: #212529;
    font-size: 14px;
}

.clear_subtitle {
    cursor: pointer;
}

.top_btn button {
    margin-right: 10px;
}

.guideEditor .tox {
    min-height: 960px !important;
}
